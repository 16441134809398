import React, { useEffect } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SpinnerGif } from '../helpers/SpinnerGif';
import './Slider.css'
import { banners } from '../../config/config';

const country = process.env.REACT_APP_COUNTRY;

const Slider = () => {
  const [loaded, setLoaded] = useState(false);
  const bannersGlobal = banners[country];

  console.log(bannersGlobal);

  useEffect(() => {
    // Precargar la primera imagen del carrusel al montar el componente
    const imageToPreload = new Image();
    imageToPreload.src = process.env.PUBLIC_URL + "banner/slider/banner_apc.webp";
    imageToPreload.onload = () => {
      setLoaded(true);
    };
  }, []);

  return (

    <div className='slider-container'>
      {!loaded ? <SpinnerGif text="Solution Box" /> : (

        <Carousel fade>
          {bannersGlobal.map((banner, index) => (
            <Carousel.Item
              key={index}
              style={banner.alt === "BannerAmd2024" ? { justifyContent: "center", display: "flex" } : {}}
            >
              {banner.alt === "BannerAmd2024"
                && <Button href="mailto:adrian_romero@solutionboxusa.com?subject=Solicitó información por AMD RYZEN 9000 Serie" className='position-absolute BtnHomePageWebCat' style={{ bottom: "80px" }}>Solicitar información</Button>
              }
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/${banner.src}`}
                alt={banner.alt}
                placeholderSrc={`${process.env.PUBLIC_URL}/${banner.placeholderSrc}`}
                width="100%"
                height="auto"
              />

              {banner.button && (
                <div className="button-cont-slider">
                  <a href={banner.button.link} className="styled-button-slider">{banner.button.text}</a>
                </div>
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      )}


    </div>

  );
};

export default Slider;
