import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Pagination, Col } from 'react-bootstrap'
import axios from 'axios'
import { BsFillGrid1X2Fill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import { authContext } from '../Context/AuthContext'
import { globalContext } from '../Context/GlobalContext'
import { SpinnerMarcas } from '../helpers/SpinnerMarcas'
import TablesCategorias from './TablesCategorias'
import CardCategorias from './CardCategorias'
import { useTranslation } from 'react-i18next'

const apiProductos = process.env.REACT_APP_API_PRODUCTOS

export const ApiCategorias = () => {
    const { codigo } = useParams();
    const [articulosPorCategoria, setArticulosPorCategoria] = useState(null);
    const [load, setLoad] = useState(false)
    const { colorTheme, handleDisplayGrid, handleDisplayList, grid, setGrid } = useContext(globalContext);
    const [isMobile, setIsMobile] = useState(false);
    const [largoTotal, setLargoTotal] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [idFound, setIdFound] = useState();
    const { token } = useContext(authContext);
    const elementosPorPagina = 7;
    const cantidadDePaginas = Math.ceil(largoTotal / elementosPorPagina);
    const navigate = useNavigate();
    const [t, i18next] = useTranslation("global")


    // Categorias 
    useEffect(() => {
        const isMobileOnLoad = window.innerWidth < 670;
        setIsMobile(isMobileOnLoad);
        const handleResize = () => {
            setIsMobile(window.innerWidth < 670);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoad(false);
                const response = await axios.get(
                    `${apiProductos}/info/categoria/${codigo}?limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)
                    }`, {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                setArticulosPorCategoria(response.data.articulos);
                localStorage.setItem('artXCategoria', JSON.stringify(response.data.articulos));
                setLoad(true);
                setLargoTotal(response.data.length);
            } catch (error) {
                console.error('Error al obtener las categorías:', error);
                setArticulosPorCategoria(null);
                setLoad(true);
            }
        };

        fetchData();
    }, [codigo, currentPage]);

    // Mostrar productos basados en las categorías almacenadas
    useEffect(() => {
        const storedCategories = localStorage.getItem('artXCategoria');
        if (storedCategories) {
            setArticulosPorCategoria(JSON.parse(storedCategories));
            setLoad(true);
        }
    }, []);


    const paginas = [];
    function renderPagination() {
        for (let i = 1; i <= cantidadDePaginas; i++) {
            paginas.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                    {i}
                </Pagination.Item>
            );
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return (
        <div className={`api-map-container bg-${colorTheme}`}>
            <img src={"https://raw.githubusercontent.com/RamiroSB/imagenes/main/rma-banner.png"} alt="Imagen marcas" className='imagenesBody' loading='lazy'></img>
            {!load ? (
                <SpinnerMarcas />
            ) : articulosPorCategoria.length > 0 ? (
                <Container fluid className='py-5' style={{ width: "100%" }}>
                    {!isMobile && (
                        <div className="display-select-container">
                            <Col className={`d-flex justify-content-end text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
                                <div class="row">
                                    <div class="span6" style={{ width: "115px" }}>
                                        <div class="mycontent-left text-center">
                                            <h6 className="text-uppercase">{t("producto.ver-como")}</h6>
                                            <div className='d-flex flex-col justify-content-around gap-1'>
                                                <div className='d-flex flex-row align-items-center svgGrilla' onClick={handleDisplayGrid}>
                                                    <BsFillGrid1X2Fill size={'1.5em'} />
                                                    {/* <h6 className='text-black m-0'>{t("producto.grilla")}</h6> */}
                                                </div>
                                                <div className='d-flex flex-row align-items-center svgGrilla' onClick={handleDisplayList}>
                                                    <FaThList size={'1.5em'} />
                                                    {/* <h6 className='text-black m-0'>{t("producto.lista")}</h6> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    )}


                    <Row className='mt-2' style={{ justifyContent: "center" }}>
                        <Col xs={12} md={12} sm={6} xl={9} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                            {isMobile ? (
                                <div className="taleShadow bg-light">
                                    <CardCategorias articulosPorCategoria={articulosPorCategoria} />
                                    <Pagination>{paginas}</Pagination>
                                    <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                                    {renderPagination()}
                                </div>


                            ) : grid ? (
                                <div className="taleShadow bg-light">
                                    <CardCategorias articulosPorCategoria={articulosPorCategoria} />
                                    <Pagination>{paginas}</Pagination>
                                    <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                                    {renderPagination()}
                                </div>
                            ) : <div className="taleShadow bg-light">
                                <TablesCategorias articulosPorCategoria={articulosPorCategoria} header={["", ""]} />
                                <Pagination>{paginas}</Pagination>
                                <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                                {renderPagination()}
                            </div>}
                        </Col>
                    </Row>
                </Container>
            ) : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', padding: '20px 0' }}>
                <p style={{ textAlign: 'center', fontSize: '24px', padding: '20px 0' }}>{t("producto.no-existe-categoria")}.</p>
                <button
                    style={{ alignSelf: 'center', background: '#ef233c' }}
                    onClick={() => navigate('/')}
                >
                    {t("producto.volver-inicio")}
                </button>
            </div>}
        </div>
    );
}



