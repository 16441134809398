import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Pagination, Col } from 'react-bootstrap'
import axios from 'axios'
import CardMarcas from "../CardMarcas/CardMarcas"
import Tables from '../Tables/Tables'
import { ImagenesMarcas } from './ImagenesMarcas'
import { BsFillGrid1X2Fill } from 'react-icons/bs'
import { FaThList } from 'react-icons/fa'
import "./CardStyle.css"
import { authContext } from '../Context/AuthContext'
import { globalContext } from '../Context/GlobalContext'
import { SpinnerMarcas } from '../helpers/SpinnerMarcas'
import { useTranslation } from 'react-i18next'

const apiProductos = process.env.REACT_APP_API_PRODUCTOS

export const ApiMap = () => {

  const { id } = useParams();
  const [idFound, setIdFound] = useState();
  const { token } = useContext(authContext);
  const { colorTheme, handleDisplayGrid, handleDisplayList, grid } = useContext(globalContext);
  const [articulosXMarca, setArticulosXMarca] = useState([]);
  const [data, setData] = useState([]);
  const [largoTotal, setLargoTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(false);
  const elementosPorPagina = 12;
  const cantidadDePaginas = Math.ceil(largoTotal / elementosPorPagina);

  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const [sentido, setSentido] = useState({});
  const [filter, setFilter] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const borrarCampos = () => {
    setMin({});
    setMax({});
    setSentido({});
    const idValue = document.getElementById('sentido');
    if (!idValue) return false
    idValue.selectedIndex = '';
  }

  const obtenerDatos = async () => {
    try {
      setLoad(false);

      const response = await axios.get(`${apiProductos}/info/marca/${id}?limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
        headers: { 'Authorization': 'Bearer ' + token }
      });

      console.log(response);
      // TO DO - Marca por articulos por marca
      setArticulosXMarca(response.data.articulos);
      setData(response.data.articulos);
      setLargoTotal(response.data.length);

      setTimeout(() => {
        setLoad(true);
      }, 1000);

      setIdFound(id);
    } catch (error) {
      console.log(error);
      setLoad(true);
    }
  };

  console.log(data, "data")

  const filtrarPrecios = async () => {
    if (!filter) setCurrentPage(1);
    setFilter(true);
    await axios.get(`${apiProductos}/info/marca/${id}?minPrice=${min.minPrice}&maxPrice=${max.maxPrice}&sentido=${sentido.sentido}&limit=${elementosPorPagina}&offset=${elementosPorPagina * (currentPage - 1)}`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
      .then(res => { console.log(res); setArticulosXMarca(res.data); setData(res.data.articulos); setLargoTotal(res.data.length); setLoad(true); setIdFound(id) })
      .catch(err => { console.log(err) })
  }

  useEffect(() => {
    if (id !== idFound) {
      setCurrentPage(1)
    }
    if (filter && id === idFound) filtrarPrecios()
    else {
      setFilter(false)
      obtenerDatos();
      borrarCampos();
    }
  }, [id, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps 

  useEffect(() => {
    const isMobileOnLoad = window.innerWidth < 670;
    setIsMobile(isMobileOnLoad);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 670);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //const marcaSolicitada = articulosXMarca;
  // const mapeoMarcaSolicitada = marcaSolicitada.info && marcaSolicitada.info.map(
  //   (llave) =>
  //     <h2>{llave.Marca}</h2>
  // )

  // const limit = 1

  // let limiteMarcaSolicitada = mapeoMarcaSolicitada?.info?.slice(0, limit);


  const paginas = [];
  function renderPagination() {
    for (let i = 1; i <= cantidadDePaginas; i++) {
      paginas.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
          {i}
        </Pagination.Item>
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const [t, i18next] = useTranslation("global")

  console.log(articulosXMarca, "marca artiulo")

  return (
    <div className={`api-map-container bg-${colorTheme}`}>
      <ImagenesMarcas id={id} />
      <Container fluid className='py-5' style={{ width: "100%" }}>
        {!isMobile && (
          <div className="display-select-container">
            <Col className={`d-flex justify-content-end text-${colorTheme === 'dark' ? 'light' : 'dark'}`}>
              <div class="row">
                <div class="span6" style={{width:"115px"}}>
                  <div class="mycontent-left text-center">
                    <h6 className="text-uppercase">{t("producto.ver-como")}</h6>
                    <div className='d-flex flex-col justify-content-around gap-1'>
                      <div className='d-flex flex-row align-items-center svgGrilla' onClick={handleDisplayGrid}>
                        <BsFillGrid1X2Fill size={'1.5em'} />
                        {/* <h6 className='text-black m-0'>{t("producto.grilla")}</h6> */}
                      </div>
                      <div className='d-flex flex-row align-items-center svgGrilla' onClick={handleDisplayList}>
                        <FaThList size={'1.5em'}/>
                        {/* <h6 className='text-black m-0'>{t("producto.lista")}</h6> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        )}

        {!load
          ? <SpinnerMarcas text={'Cargando marca'} />
          :
          <Row className='mt-2' style={{ justifyContent: "center" }}>

            <Col xs={12} md={12} sm={6} xl={9} lg={12} style={{ display: "flex", justifyContent: "center" }}>
              {articulosXMarca.length <= 0 ? <h3>{t("producto.no-existe")}</h3> : isMobile ?
                <div className="taleShadow bg-light card-container">
                  <CardMarcas articulosXMarca={articulosXMarca} style={{ display: "flex", justifyContent: "center" }} />
                  <Pagination>{paginas}</Pagination>
                  <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                  {renderPagination()}
                </div>
                :
                grid ?
                  <div className="taleShadow bg-light card-container">
                    <CardMarcas articulosXMarca={articulosXMarca} style={{ display: "flex", justifyContent: "center" }} />
                    <Pagination>{paginas}</Pagination>
                    <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                    {renderPagination()}
                  </div>
                  :
                  <div className="taleShadow bg-light">
                    <Tables articulosXMarca={articulosXMarca} header={["", ""]} />
                    <Pagination>{paginas}</Pagination>
                    <div>{t("producto.pagina")} {currentPage} {t("producto.de")} {cantidadDePaginas}</div>
                    {renderPagination()}
                  </div>
              }
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}
